import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import brightsparks2 from '../assets/images/brightsparks2.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import nus_logo from '../assets/images/nus-logo.png'
import cvwo_logo from '../assets/images/cvwo-logo.png'
import mcf_logo from '../assets/images/mcf-logo.png'
import { GitHub, Mail, LinkedIn } from '@material-ui/icons'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Leow Wen Bin" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>About Me</h2>
                </header>
                <p>
                  Hello! I am (Donovan) Leow Wen Bin, a Computer Science undergraduate in the&nbsp;
                  <a href="http://www.nus.edu.sg/">National University of Singapore</a>.
                  Having studied Computer Science since the age of 14, I've grown a very
                  strong passion for and interest in the subject, especially the field of&nbsp;
                  <a href="https://credentials.nus.edu.sg/c214e657-be97-4a4d-bc2b-d2913de9f173">Artificial Intelligence</a>.
                  This passion, together with a consistent work ethic and
                  endless desire to learn, has led to me achieving excellence
                  throughout my academic history.
                  <br/>
                  <br/>
                  I chose a career in technology because I believe it has the potential to
                  improve our lives, even if in small, incremental ways. My hope is that
                  the software I create will benefit people everywhere!
                  {/*
                  I am a recipient of the&nbsp;
                  <a href="https://www.smartnation.sg/resources/smart-nation-scholarship">
                    Smart Nation Scholarship
                  </a>
                  &nbsp;offered by the &nbsp;
                  <a href="https://www.tech.gov.sg/">
                    Government Technology Agency of Singapore (GovTech)
                  </a>
                  .*/}
                </p>
                {/*<ul className="actions">
                  <li>
                    <Link to="/generic" className="button">
                      Learn More
                    </Link>
                  </li>
                  </ul>*/}
              </div>
              <span className="image">
                <img src={brightsparks2} alt="Yours Truly" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Work and Education</h2>
              <p style={{"marginBottom": "0px"}}>
                Here are some of the notable aspects of my journey.
              </p>
              <p>
                A full timeline of my education, work, and project history can be found&nbsp;
                <Link to="/timeline">
                  here.
                </Link>
              </p>
            </header>
            <ul className="features">
              <li>
                <div className="circleimage">
                  <span className="image">
                    <img src={nus_logo} alt="National University of Singapore" />
                  </span>
                </div>
                {/*<span className="icon major style1 fa-code"></span>*/}
                <h3>National University of Singapore (2018-2022)</h3>
                <p>
                  Bachelor of Computing in Computer Science; Dean's List of AY2019/2020 Semesters
                  1 and 2
                </p>
              </li>
              <li>
                <div className="circleimage">
                  <span className="image">
                    <img src={cvwo_logo} alt="Computing for Voluntary Welfare Organisations" />
                  </span>
                </div>
                {/*<span className="icon major style3 fa-copy"></span>*/}
                <h3>Computing for Voluntary Welfare Organisations (2019)</h3>
                <p>
                  Software Engineering intern, responsible for backend development of a Ruby on
                  Rails web application
                </p>
              </li>
              <li>
                <div className="circleimage">
                  <span className="image">
                    <img src={mcf_logo} alt="MyCareersFuture Singapore" />
                  </span>
                </div>
                {/*<span className="icon major style5 fa-diamond"></span>*/}
                <h3>MyCareersFuture Singapore <br/>(2020)</h3>
                <p>
                  Machine Learning intern, responsible for improving an NLP resume parser and
                  ensuring its continued performance
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/timeline" className="button">
                    Full Timeline
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Skills and Proficiencies</h2>
              <p style={{"marginBottom": "0px"}}>
                Here are some of the skills and proficiencies that I have. They are shown
              </p>
              <p>
                along with related frameworks and technologies.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-laptop"></span>
                <strong>WebApps</strong>
                <br/>
                Ruby on Rails
                <br/>
                React-Redux
                <br/>
                Flask
              </li>
              <li className="style2">
                <span className="icon fa-code-fork"></span>
                <strong>S.W.&nbsp;Engineering</strong>
                <br/>
                Git
                <br/>
                Docker
                <br/>
                Gitlab CI
              </li>
              <li className="style3">
                <span className="icon fa-bar-chart"></span>
                <strong>Machine&nbsp;Learning</strong>
                <br/>
                Pytorch
                <br/>
                Keras
                <br/>
                spaCy
              </li>
              <li className="style4">
                <span className="icon fa-code"></span>
                <strong>Others</strong>
                <br/>
                Java
                <br/>
                SQL
                <br/>
                C++
              </li>
              {/*<li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>Game Development</strong> Unity
              </li>*/}
            </ul>
            {/*<p className="content">
            <p className="major">
              My skills and knowledge span a wide range of tech fields. This is the result
              of a diverse project history and an openness to new experiences.
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>*/}
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Contact</h2>
              <p>
                Please feel free to get in touch with me using the links below!
              </p>
                      
              <ul className="icons">
                <li>
                  <a className="icon" href="https://github.com/LeowWB/">
                    <GitHub/>
                  </a>
                </li>
                <li>
                  <a className="icon" href="https://www.linkedin.com/in/leowwb/">
                    <LinkedIn/>
                  </a>
                </li>
                <li>
                  <a className="icon" href="mailto:dleowwb@gmail.com">
                    <Mail/>
                  </a>
                </li>
              </ul>
            </header>
            {/*
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>*/}
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
