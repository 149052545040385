import React from 'react'

import logo from '../assets/images/logo.png'

const Header = (props) => (
    <div>
        <header id="header" className="alt">
            <span className="logo"><img src={logo} alt="" /></span>
            <h1>Leow Wen Bin</h1>
            <p style={{"marginBottom": "0px"}}>
                Software Developer
            </p>
            <p>
                Computer Science Undergraduate
            </p>
        </header>
    </div>
)

export default Header
